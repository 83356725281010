<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="d-none d-md-block">
      <div :class="xlDevice ? 'section1-xl' : 'section1-desktop'">
        <div class="sora white--text">
          We’ve got<br>
          what matters<br>
          covered.
        </div>
        <div class="cta-btn">
          <v-btn
            class="mint darkgrey--text font-weight-bold text-h4"
            large
            to="/afficiencyterm/survey"
          >
            Get started on your term life policy
          </v-btn>
        </div>
      </div>
      <div class="section2 pb-16">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Term life coverage that protects you and your loved ones
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                We’ve got uncertainty covered.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex justify-center mt-16 mb-n6">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-account-cash
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-tablet-cellphone
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-help-box
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="10"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Term life coverage that’s affordable and reliable
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                <!-- 10-Day Free Look <br> Period -->
                Digital convenience for your busy life
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Support along the way
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="10"
            class="d-inline-flex"
          >
            <v-col class="d-flex flex-column justify-center s2-desc">
              We’ll make sure you get coverage that fits your budget and brings you and your family peace of mind.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Purchase and access your insurance coverage easily and at any time.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Our Blanket agents will assist you with any questions or concerns you may have throughout the process, and beyond.
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/afficiencyterm/survey"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  You and your family, covered.
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Protect your family’s future with our our term life insurance that:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in termLifeNeeds"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Additional options include:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in optionalCoverages"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Flexibility and convenience throughout the process including:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in processingFeatures"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got the unexpected<br> covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Don’t put off until tomorrow what you can do for yourself and your family today.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/afficiencyterm/survey"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your term life policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          src="/img/renters-2022/Mobile-app-mockup.png"
          :class="xlDevice ? 'sec5-xl-phone' : 'sec5-dsk-phone'"
        ></v-img>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-desk"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------TABLET------------------------------------------------------>
    <div v-show="tabletDevice">
      <div class="section1-tablet">
        <div class="sora-tablet white--text">
          We’ve got<br>
          what matters<br>
          covered.
        </div>
        <div class="cta-btn-tablet">
          <v-btn
            class="mint darkgrey--text font-weight-bold text-h4"
            large
            to="/afficiencyterm/survey"
          >
            Get started on your term life policy
          </v-btn>
        </div>
      </div>
      <div class="section2-tablet pb-16 px-2">
        <div class="s2-title">
          <v-row class="d-flex justify-center">
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <div class="section2-header">
                Term life coverage that protects you and your loved ones
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-center">
              <div class="s2-sub">
                We’ve got uncertainty covered.
              </div>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex align-start justify-center mt-16 mb-n6">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-account-cash
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-tablet-cellphone
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center"
            >
              <v-icon
                class="primary--text s2-icon"
              >
                mdi-help-box
              </v-icon>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0 py-0">
          <v-col
            cols="12"
            class="d-inline-flex align-center"
          >
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Term life coverage that’s affordable and reliable
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                <!-- 10-Day Free Look <br> Period -->
                Digital convenience for your busy life
              </div>
            </v-col>
            <v-col class="d-flex justify-center">
              <div class="s2-subhead">
                Support along the way
              </div>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mt-0">
          <v-col
            cols="12"
            class="d-inline-flex"
          >
            <v-col class="d-flex justify-center s2-desc">
              We’ll make sure you get coverage that fits your budget and brings you and your family peace of mind.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Purchase and access your insurance coverage easily and at any time.
            </v-col>
            <v-col class="d-flex justify-center s2-desc">
              Our Blanket agents will assist you with any questions or concerns you may have throughout the process, and beyond.
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center"
            cols="12"
          >
            <v-btn
              class="mint text-h4 font-weight-bold darkgrey--text"
              large
              to="/afficiencyterm/survey"
            >
              Apply Now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4 pt-16">
        <v-row class="d-flex justify-start">
          <v-col>
            <v-row>
              <v-col
                cols="12"
                lg="10"
                xl="8"
                class="pl-0"
              >
                <div class="sec4-title">
                  You and your family, covered.
                </div>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Protect your family’s future with our our term life insurance that:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in termLifeNeeds"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>

            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Additional options include:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in optionalCoverages"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
            <v-row class="d-flex justify-start">
              <v-col
                cols="12"
                lg="12"
                xl="8"
                class="pt-10"
              >
                <div class="sec4-sub">
                  Flexibility and convenience throughout the process including:
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center">
              <v-col
                v-for="item in processingFeatures"
                :key="item"
                cols="12"
                class="d-flex justify-start align-start covg-txt"
              >
                <v-icon
                  left
                  large
                  class="primary--text"
                >
                  mdi-check-bold
                </v-icon>
                {{ item }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="sec5-dsk">
        <v-row>
          <v-col class="sec5-title">
            We’ve got the unexpected<br> covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="sec5-sub">
            Don’t put off until tomorrow what you can do for yourself and your family today.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              large
              class="mint apply-btn ml-4"
              to="/afficiencyterm/survey"
            >
              <span class="text-h4 font-weight-bold darkgrey--text">Get your term life policy today</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-tablet"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
    <!------------------------------------------MOBILE------------------------------------------------------>
    <div v-if="mobileDevice">
      <div
        class="section1-mobile"
      ></div>
      <div class="section2-mobile">
        <div class="section2-title-mobile">
          <v-row class="mb-5">
            <v-col class="pt-5">
              We’ve got what <br>
              matters covered.
            </v-col>
          </v-row>
          <v-btn
            x-large
            class="mint mx-auto py-8"
            to="/afficiencyterm/survey"
          >
            <span class="section2-btn-txt-mobile pt-1">get started on <br>your term life policy</span>
          </v-btn>
        </div>
      </div>
      <div class="section3-mobile">
        <v-row class="pt-10">
          <v-col class="section3-title-mobile">
            Term life coverage that protects you and your loved ones
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="section3-sub-mobile">
            We’ve got uncertainty covered.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-account-cash
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Term life coverage that’s affordable and reliable
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            We’ll make sure you get coverage that fits your budget and brings you and your family peace of mind.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              large
              class="primary--text"
            >
              mdi-tablet-cellphone
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            <!-- 10-Day Free Look Period -->
            Digital convenience for your busy life
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Purchase and access your insurance coverage easily and at any time.
          </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col class="text-center">
            <v-icon
              class="primary--text"
              large
            >
              mdi-help-box
            </v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-title-mobile">
            Support along the way
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="covg-txt-mobile text-center">
            Our Blanket agents will assist you with any questions or concerns you may have throughout the process, and beyond.
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col class="d-flex justify-center">
            <v-btn
              class="mint darkgrey--text text-h4 font-weight-bold mr-0"
              to="/afficiencyterm/survey"
            >
              apply now
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="section4-mobile pb-10">
        <v-row class="pt-8">
          <v-col class="s4-title-mobile">
            You and your family, covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            Protect your family’s future with our our term life insurance that:
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item in termLifeNeeds"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            Additional options include:
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item in optionalCoverages"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s4-sub-mobile">
            Flexibility and convenience throughout the process including:
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="item in processingFeatures"
            :key="item"
            cols="12"
            class="d-flex justify-start align-start covg-txt"
          >
            <v-icon
              left
              large
              class="primary--text"
            >
              mdi-check-bold
            </v-icon>
            {{ item }}
          </v-col>
        </v-row>
      </div>
      <div class="s5-mobile">
        <v-row class="pt-10">
          <v-col class="s5-title-mobile">
            We’ve got the unexpected<br> covered.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="s5-sub-mobile">
            Don’t put off until tomorrow what you can do for yourself and your family today.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center pt-6">
            <v-btn
              x-large
              class="mint mx-auto py-8"
              to="/afficiencyterm/survey"
            >
              <span class="section2-btn-txt-mobile pt-1">Get your term life policy today</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-img
          class="phone-mobile"
          src="/img/phone-mobile.png"
        ></v-img>
      </div>
      <div class="faq-sec-desk pt-16 pb-16">
        <!-- Dynamic FAQ LIST -->
        <div
          v-for="(faq,index) in mainFaqList"
          :key="index"
          class="my-4"
        >
          <v-row>
            <div
              class="cov-faq-title"
              @click="toggleFaq(index)"
            >
              <v-col
                cols="12"
                class="faq-header-mobile"
              >
                {{ faq.title }}
                <v-icon
                  v-if="!faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-right
                </v-icon>
                <v-icon
                  v-if="faq.status"
                  right
                  x-large
                  color="#005F6C"
                >
                  mdi-chevron-down
                </v-icon>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="faq.status">
            <v-col>
              <v-expansion-panels
                focusable
                accordion
              >
                <v-expansion-panel
                  v-for="item in faq.list"
                  :key="item.id"
                >
                  <v-expansion-panel-header class=" text-h4 font-weight-bold">
                    {{ item.ques }}
                    <template v-slot:actions>
                      <v-icon
                        color="#005F6C"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div>
                      <br>
                    </div>
                    <div v-html="item.ans"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>

        <!-- Dynamic FAQ LIST End-->
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Dashboard',

    data () {
      return {
        covid19Faq: false,
        covFaq: false,
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
        termLifeNeeds: [
          'Gives you life insurance protection at an affordable rate.', 'Provides coverage amounts starting at $25,000 up to $1 million.', 'Allows for predictable level premium periods: 10, 15, or 20 years.'
        ],
        optionalCoverages: [
          'LegacyShield is a digital vault service and legacy preparation tools to help you better organize your life today and more easily plan for tomorrow.', 'Accelerated Death Benefit Rider expedites a portion of the policy’s death benefit if you become terminally ill.'
        ],
        processingFeatures: [
          'A fast and easy online application.', 'Premium payment choices: annually, semi-annually, quarterly, or monthly.', 'Convenient payment methods.'
        ],
        mainFaqList: [
          {
            status: false,
            title: 'FAQs',
            list: [
              {
                ques: 'What is Term Life Insurance?',
                ans: 'Life insurance is a contract between you and your insurance company. You pay level premiums, and in return your beneficiary or beneficiaries are guaranteed to receive a lump sum of money, or death benefit, should you die within the specified term of the policy. Money from the policy can provide financial security for your loved ones and can replace your income or help pay for things like final expenses.<br><br>The cost of having life insurance is far more affordable than most people realize. In addition, the proceeds of life insurance are almost never subject to federal income taxes. There are plenty of term life insurance options available, to fit every situation and budget.'
              },
              {
                ques: 'Why buy life insurance?',
                ans: 'We often avoid thinking about what would happen in the event of our passing, but it is important to plan for how your loved ones would cope if you were no longer around and able to contribute financially. Loved ones can include your spouse, children, aging parents, as well as anyone else who depends on your earnings to make ends meet.<br><br>Everyday expenses from childcare and putting food on the table to larger commitments like mortgage payments do not disappear once you are gone. It is important to consider the financial future of your family if the unthinkable were to happen.'
              },
              {
                ques: 'What is a beneficiary?',
                ans: 'A beneficiary is the person or entity you select to receive the death benefit from your policy when you die. A beneficiary can be any adult person, your favorite charity, a nonprofit organization, business, or other entity. You also may establish a trust for your young children and name the trust as a beneficiary'
              },
              {
                ques: 'Don’t you need a medical exam to get life insurance coverage?',
                ans: 'Our goal is to provide you with easy-to-understand and easy to purchase life insurance that fits your needs and is available 100% online. We have teamed with leading industry partners which means you can access life insurance products that do not require a time-consuming and sometimes invasive medical exam.<br><br>Instead, we rely on an easy-to-navigate platform that offers instant-issue term life policies. Once you provide your payment details and accept the offer, you are automatically covered. As long as you meet your premium payment obligations, your policy will remain in force for the specified life of the policy.'
              },
              {
                ques: 'How long does the application and approval process take?',
                ans: 'The application process is 100% digital, meaning it is all completed online. You can expect to complete the application within several minutes. And the approval process is just as quick, you can also expect a response in just a few minutes.'
              },
              {
                ques: 'What type of questions are included on the application?',
                ans: 'You will be asked basic information about yourself such as your name, address, phone number, date of birth, social security number, citizenship, or residency status as well as a series of questions relating to your overall health including height and weight, smoking or nicotine use, any medical diagnosis, use of prescription medications as well as previous or current treatments and/or procedures. It is helpful to have that information on hand so you can complete the application.'
              },
              {
                ques: 'Tell me more about term life insurance',
                ans: 'Term life insurance is a type of life insurance that\'s designed to cover a specified period, known as the term. As long as you make your premium payments on time, your policy will not expire, and you will have coverage. Importantly, the premiums remain the same throughout your life regardless of your age, health, or lifestyle changes.'
              },
              {
                ques: 'What are the advantages of term life coverage?',
                ans: 'Term life insurance provides coverage for when you feel you need it the most. For example, if you have recently taken a new mortgage, it may be prudent to take a term policy at that time, so your dependents are not burdened with the mortgage payments in the event of your untimely death. As long as you keep paying your premiums, your coverage will continue for the term of the policy.<br><br>Your premium stays the same for the term of the policy, so you don\'t have to worry about it increasing with age.'
              },
              {
                ques: 'How much term life insurance will I need?',
                ans: 'Figuring out how much life insurance to buy depends on your annual income and how long your family will need to rely on this income, but you should also consider your assets, debt, final expenses, childcare and education costs.'
              },
              {
                ques: 'How can I pay for my policy?',
                ans: 'You can either pay for your policy premium via credit card or via bank draft. There are usually four payment modalities – monthly, quarterly, half-yearly, and annually. Your credit card or bank account will automatically be debited with your premium amount when due.'
              },
            ],
          },
        ],
      }
    },
    computed: {
      xlDevice () {
        return this.$vuetify.breakpoint.xlOnly
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      largeDevice () {
        return this.$vuetify.breakpoint.lgAndUp
      },
      mediumDevice () {
        return this.$vuetify.breakpoint.mdOnly
      },
    },
    created () {
      // this.$gtag.pageview('Travel USA')
      this.currentuser = this.$store.getters.getuser
    },

    methods: {
      toggleFaq (index) {
        this.mainFaqList[index].status = !this.mainFaqList[index].status
      },
      toggleCovid19Faq () {
        this.covid19Faq = !this.covid19Faq
      },
      goTo (route) {
        this.$router.push({ path: route })
      },
      navigateTo (product) {
        this.$router.push(product)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
.s2-icon {
  font-size: 50px !important;
}

.apply-btn {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 22.6667px;
line-height: 125%;
/* or 28px */

text-align: center;
letter-spacing: 0.944445px;
text-transform: uppercase;

/* Color Palette/Dark Grey */

color: #292F36;
}

.s2-desc {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}
  .s2-subhead {
    font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
  }
  .s2-sub {
    font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
  }

  .cta-btn {
    position: absolute;
    top: 78%;
    left: 9%;
  }

  .cta-txt {
    font-weight: 700;
    font-size: 17.9859px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.749412px;
    text-transform: uppercase;
    color: #292F36;
    padding: 0 !important;
  }
  .sora {
position: absolute;
top: 45%;
left: 9%;
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
color: #FFFFFF;
  }

  .section2-header {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.42px;
color: #292F36;
  }

  .s2-title {
    padding-top: 79px;
  }
.title-text-mobile {
    font-weight: 500;
    color: #616161;
  }
.cover{
  font-size: 55px;
  color: #757575;
}
.read-more{
  font-size: 18px;
  color: #00A1B7;
}
.quote-btn{
  font-weight: bold
}
.section1-desktop{
  position: relative;
  width: 100vw;
  height: 600px;
  background: url("/img/term-life-2022/Hero-Section.jpg");
  background-size: cover;
}

.section1-xl{
  position: relative;
  width: 100vw;
  height: 750px;
  background: url("/img/term-life-2022/Hero-Section.jpg");
  background-size: cover;
}
.section2 {
position: relative;
background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
/* background-color: #FFFFFF; */
}
.spacer {
  height: 6rem;
  z-index: 2;
}

.title-text-tablet {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
}
.pet-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
z-index: 1;
background-color: white;
}
.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}
.btn-mrg1 {
  margin-left: 0;
}
.btn-mrg2 {
  margin-left: 200px;
}
.cov-grp {
  background-color: white;
}
.small {
    height: 700px;
}
.large {
    height: 900px;
}
.zoom {
    transition: transform .2s;
}

.zoom:hover {
    transform: scale(1.2);
}

.section3 {
  position: relative;
  height: 700px;
  background-color: #005F6C;
}

.review-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #FFFFFF;
}

.review-sub {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
/* or 38px */

letter-spacing: -0.424019px;

/* white */

color: #FFFFFF;
}

.review-details {
  font-family: 'Poppins';
font-style: italic;
font-weight: 300;
font-size: 21px;
line-height: 140%;
color: #FFFFFF;
}

.review-sig {
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 21px;
line-height: 140%;
/* or 29px */

/* white */

color: #FFFFFF;
}

.review-ins {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 21px;
line-height: 140%;
/* or 29px */

/* white */

color: #FFFFFF;
}

.portrait-card {
position: absolute;
width: 615px;
height: 700px;
left: 880px;
top: -30px;
background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(1, 0, 0, 0) ), url('/img/pet-review-portrait.png');
border-radius: 10px;
}

.portrait-quote {
  width: 300px;
height: 364px;

font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 72px;
line-height: 100%;
/* or 72px */

letter-spacing: -0.636172px;

/* Color Palette/White (100%) */

color: #FFFFFF;
}

.portrait-text-container {
  margin-top:100px;
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 20px 90px 20px 30px;
width: 100%;
height: 251px;
background: #00A1B7;
flex: none;
order: 3;
align-self: stretch;
flex-grow: 0;
z-index: 3;
border-radius: 0 0 0 10px;
}

.portrait-text {
  font-family: 'Poppins';
font-style: italic;
font-weight: 500;
font-size: 17.9695px;
line-height: 125%;
letter-spacing: -0.202611px;
color: #FFFFFF;
}

.signature-container{
  position: absolute;
  right: 10rem;
  top: 42rem;
}

.section4 {
background: #FFFFFF;
padding-left: 10%;
padding-right: 10%;
padding-bottom: 163px;
}

.sec4-title {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */

letter-spacing: -0.424019px;

/* Color Palette/Dark Grey */

color: #292F36;
}

.sec4-sub {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 28px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
}

.covg-cta-txt {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 38px;
line-height: 110%;
/* or 42px */

letter-spacing: -0.424019px;

/* midnight eagle green */

color: #005F6C;
}

.learn-btn-txt {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 125%;
/* identical to box height, or 25px */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;

/* gunmetal */

color: #292F36;
}

.sec5-dsk {
position: relative;
height: 438px;
background-image: url('/img/partner-background.jpg');
background-position: center;
background-size: cover;
padding-left: 10%;
padding-right: 10%;
}

.sec5-dsk-phone {
  position: absolute;
left: 726px;
top: -78px;
}

.sec5-xl-phone {
  position: absolute;
left: 1100px;
top: -78px;
}

.sec5-title {
  position: absolute;
  left: 130px;
  top: 34px;
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
/* or 46px */

letter-spacing: -0.424019px;

/* Color Palette/White (100%) */

color: #FFFFFF;
}

.sec5-sub {
  position: absolute;
  left: 130px;
  top: 131px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 125%;
width: 598px;
height: 120px;
/* or 40px */

letter-spacing: -0.424019px;

/* white */

color: #FFFFFF;
}

.apply-btn {
  position: absolute;
  left: 130px;
  top: 289px;
}

.faq-sec-desk {
  background: #FFFFFF;
  padding-left: 10%;
  padding-right: 10%;
}

.faq-header-desk {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.faq-panel-icon {
  color: #005F6C;
}

.cov-faq-title:hover {
  cursor: pointer;
}

.poppins-300 {
  font-family: 'Poppins';
  font-weight: 300;
}
/*-----------------------------MOBILE STYLES-----------------------------*/
.section1-mobile {
  background-image: url('/img/term-life-2022/Hero-img-tablet.jpg');
  background-position: center;
  background-size: cover;
  height: 375px;
}

.section2-mobile {
width: 100vw;
height: 260px;
background: #00A1B7;
}

.section2-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;

}

.section2-btn-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 125%;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;
color: #292F36;
}

.section3-mobile {
  padding-bottom: 54px;
  background-image: url('/img/knit-mobile.png');
  background-position: center;
  background-size: cover;
  padding-left: 10px;
  padding-right: 10px;
}

.section3-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #292F36;
}

.section3-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
}

.covg-txt-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}

.section4-mobile {
  padding-bottom: 54px;
  padding-left: 10px;
  padding-right: 10px;
  background: #FFFFFF;
}

.s4-title-mobile {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
letter-spacing: -0.511589px;
color: #292F36;
}

.s4-sub-mobile {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
letter-spacing: -0.424019px;
color: #292F36;
}

.covg-txt-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 150%;
color: #292F36;
text-align: left;
}

.panel-title-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 150%;
display: flex;
align-items: center;
color: #005F6C;
}

.panel-icon {
  color: #292F36;
  font-size: 20px !important;
}

::v-deep .v-expansion-panel-header--active .v-icon {
  color: #FFFFFF !important;
}

.s5-mobile {
  position: relative;
  min-height: 725px;
  padding-left: 10px;
  padding-right: 10px;
  background-image: url('/img/partner-background-mobile.jpg');
  background-position: center;
  background-size: cover;
}

.s5-title-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 110%;
text-align: center;
letter-spacing: -0.511589px;
color: #FFFFFF;
}

.s5-sub-mobile {
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 26px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #FFFFFF;
}

.phone-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 300px;
  width: auto;
}

.faq-header-mobile {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

/*-----------------------------TABLET STYLES-----------------------------*/
.section1-tablet {
  position: relative;
  width: 100vw;
  height: 600px;
  background: url("/img/term-life-2022/Hero-Section.jpg");
  background-position: center;
  background-size: cover;
}

.section2-tablet {
  position: relative;
background-image: url('/img/pattern-white-background.png');
background-position: center;
background-size: cover;
mix-blend-mode: normal;
/* background-color: #FFFFFF; */
}

.faq-header-tablet {
  font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
letter-spacing: -0.424019px;
color: #005F6C;
}

.sora-tablet {
  position: absolute;
top: 45%;
left: 5%;
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 110%;
color: #FFFFFF;
}

.cta-btn-tablet {
    position: absolute;
    top: 78%;
    left: 5%;
  }

  ::v-deep .v-expansion-panel-header {
    color:#00A1B7;
  }

  ::v-deep .v-expansion-panel--active .v-expansion-panel-header {
    background:#00A1B7;
    color:#FFFFFF;
  }
</style>
